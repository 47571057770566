@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

.wrapper {
  .student-info {
    padding-bottom: rem(52);
    .other-logins {
      padding: rem(16);
      .button {
        color: #bdbdbd;
        font-size: rem(16);
        font-weight: 400;
        text-align: right;
        user-select: none;
        white-space: nowrap;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          color: #7175f5;
        }
      }
    }
    .avatar-wrapper {
      padding: rem(30) rem(12) rem(12);
      .avatar {
        border-radius: 50%;
        width: rem(126);
        height: rem(126);
        object-fit: contain;
      }
    }
    .name {
      color: #000;
      text-align: center;
      font-size: rem(24);
      font-weight: 500;
    }
    .sub-info {
      padding-top: rem(12);
      color: #bdbdbd;
      font-size: rem(18);
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      & > div {
        flex: 1;
        &:first-child {
          text-align: right;
          padding-right: rem(6);
          margin-left: rem(-50);
        }
        &:last-child {
          text-align: left;
        }
      }
    }
    .auth-button-wrapper {
      padding: rem(18) rem(12) rem(14);
      display: flex;
      justify-content: center;
      align-items: center;
      .auth-button {
        min-width: rem(285);
      }
    }
    .agreements {
      padding: rem(12) rem(40) rem(0);
      margin-bottom: rem(-28);
    }
  }
}
