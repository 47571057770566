@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

.ant-modal {
  .ant-modal-content {
    background: none;
    box-shadow: none;

    .ant-modal-body {
      margin: auto;
      padding: 0;
    }
  }
}

.ant-form-item {
  margin-bottom: rem(25);
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item-explain {
  min-height: rem(24);
}

.ant-form-item-has-error {
  .ant-input-lg {
    background-color: #fff !important;
    box-shadow: 0 0 0 rem(1000) #fff inset !important;
    border-color: #ff4d4f !important;
    border: rem(2) solid #f45940;
    box-sizing: border-box;
    border-radius: rem(36);
  }
}

.ant-btn.ant-btn-loading::before {
  display: none;
}
