@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

.qrcodeContainer {
  padding: rem(44) 0 rem(34) 0;
}

.modalWidget {
  position: absolute;
  top: rem(-156);
  left: rem(106);
  width: rem(250);
}
.qrcode {
  width: rem(260);
  height: rem(260);
}

.text {
  margin-top: rem(10);
  font-style: normal;
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(30);
  text-align: center;
  color: #555555;
}
