@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hidden {
  display: none !important;
}

html[theme='light'] {
  --link-color: #3187ff;
  --title-color: #000d20;
  --card-bg: #fff;
  --input-bg: #f5f5f5;
  --input-selected-bg: #ffe6df;
  --xigua-primary-color: linear-gradient(180deg, #ff6846 0%, #fc4016 76.5%, #da2800 100%);
  --xigua-primary-shadow: 0px 3px 8px 0px rgba(176, 32, 0, 0.25);
  --xigua-primary-weak-color: linear-gradient(
    180deg,
    rgba(255, 104, 70, 0.4) 0%,
    rgba(252, 64, 22, 0.4) 76.5%,
    rgba(218, 40, 0, 0.4) 100%
  );
}

html[theme='dark'] {
  --link-color: #3187ff;
  --title-color: white;
  --card-bg: #e5eaf3;
  --input-bg: #e5eaf3;
  --input-selected-bg: #ffe6df;
  --xigua-primary-color: linear-gradient(180deg, #ff6846 0%, #fc4016 76.5%, #da2800 100%);
  --xigua-primary-shadow: 0px 3px 8px 0px rgba(176, 32, 0, 0.25);
  --xigua-primary-weak-color: linear-gradient(
    180deg,
    rgba(255, 104, 70, 0.4) 0%,
    rgba(252, 64, 22, 0.4) 76.5%,
    rgba(218, 40, 0, 0.4) 100%
  );
}
