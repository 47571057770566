@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

.loginPage {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #4397ec;
  width: 100%;
  min-width: 1280px;
  height: 100%;
  min-height: 100vh;
  color: white;
  text-align: center;
  padding-top: rem(40);
}

.logo {
  margin-left: rem(200);
}

.login {
  margin: auto;
  margin-right: rem(350);
  width: rem(500);
  text-align: center;

  .loginBlock {
    margin-top: rem(70);
  }
}

.settingButton {
  position: absolute;
  right: rem(192);
  top: rem(40);
  z-index: 1;
}
